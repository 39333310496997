import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";

function AppcheckBeyondPoints() {
  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href="https://www.classdojo.com/mobile-only/"
        data-app="https://www.classdojo.com/mobile-only/"
        data-app-ios="classdojo://p/homePoints"
        data-app-android="classdojo://p/homePoints"
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckBeyondPoints;
